import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const SvgIcon = ({ icon, className }) => (
  <svg className={classNames('icon', `icon-${icon}`, className)}>
    <use xlinkHref={`#icon-${icon}`} />
  </svg>
);

SvgIcon.propTypes = {
  icon: PropTypes.oneOf([
    'external',
    'percentage',
    'star_rate',
    'credit-card',
    'icon-payments',
    'icon-list',
    'icon-balance',
    'twitter',
    'instagram',
    'facebook',
    'clinic',
    'clinics',
    'clinic-speciality',
    'doctor',
    'pill',
    'queue',
    'revenue',
    'speciality',
    'syringe',
    'voucher',
    'minus',
    'eye-o',
    'alert-o',
    'close-o',
    'alert',
    'close',
    'eye-slash-o',
    'menu',
    'arrow-left',
    'info',
    'tick-o',
    'tick',
    'cross',
    'notification-o',
    'arrow-down',
    'calendar-o',
    'group-o',
    'service',
    'time',
    'logout',
    'arrow-up',
    'upload',
    'delete-o',
    'edit-pencil',
    'arrow-down-long',
    'options',
    'arrow-right',
    'plus',
    'search',
    'filter',
    'square-o',
    'square-checked',
    'user',
    'checkbox-mixed',
    'circle-checked',
    'circle-o',
    'stepper-active',
    'stepper-normal',
    'info-o',
    'up-round',
    'timezone',
    'arrow-up-long',
    'spinner',
    'grid',
    'back-arrow',
    'download',
    'right-arrow',
    'video-off',
    'video',
    'stop',
    'settings',
    'screen-swap',
    'screen-split-off',
    'screen-split',
    'screen-share-off',
    'screen-share',
    'play',
    'microphone-off',
    'microphone',
    'call-end',
    'broken-connection',
    'link',
    'record',
    'chat',
    'video-file',
    'picture',
    'pdf',
    'expand',
    'attachment',
    'shrink',
    'checked',
    'location',
    'live-preview',
    'copy',
    'arrow-left-long',
    'time-fill',
    'list-checked',
    'options-vertical',
    'template',
    'card-custom',
    'user-o',
    'right-arrow-round',
    'chat-fill',
    'chat-o',
    'lock-alt',
    'drag',
    'photo',
    'attention',
    'hat-o',
    'settings-o',
    'embedded',
    'client-start',
    'whatsapp',
  ]).isRequired,
  className: PropTypes.string,
};

SvgIcon.defaultProps = {
  className: undefined,
};

export default SvgIcon;
